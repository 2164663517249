import { create } from 'zustand'
import digBg0 from './assets/dig-bg/0.png'
import digBg1 from './assets/dig-bg/1.png'
import digBg2 from './assets/dig-bg/2.png'
import digBg3 from './assets/dig-bg/3.png'
import digBg4 from './assets/dig-bg/4.png'
import digBg5 from './assets/dig-bg/5.png'
import digBg6 from './assets/dig-bg/6.png'
import digBg7 from './assets/dig-bg/7.png'

import minerDefault from './assets/miner-default.png'
import minerSad from './assets/miner-sad.png'
import minerAnimation from './assets/miner.gif'
import { usePreloadImages } from '@/hooks/usePreloadImages'

import bg1 from './assets/bg1.png'
import bg2 from './assets/bg2.png'
import prizeButtonImage from './assets/prize-button.png'
import miningButtonImage from './assets/mining-button.png'
import treasureImage from './assets/treasure.png'
// import treasureShadowImage from './assets/treasure-shadow.png'
import hoeImage from './assets/hoe.png'
import decoratorImage from './assets/decorator.png'
import userAvatar from './assets/users/1.png'

export const usePreloadTreasureImages = () => {
  usePreloadImages([
    digBg0,
    digBg1,
    digBg2,
    digBg3,
    digBg4,
    digBg5,
    digBg6,
    digBg7,
    minerDefault,
    minerSad,
    minerAnimation,
    bg1,
    bg2,
    prizeButtonImage,
    miningButtonImage,
    hoeImage,
    decoratorImage,
    userAvatar,
    treasureImage
  ])
}

export const DISTANCE_POINTS = [100, 78, 51, 34, 23, 14, 0.3, 0]

export const digBg = (distance: number) => {
  switch (distance) {
    case DISTANCE_POINTS[1]:
      return digBg1
    case DISTANCE_POINTS[2]:
      return digBg2
    case DISTANCE_POINTS[3]:
      return digBg3
    case DISTANCE_POINTS[4]:
      return digBg4
    case DISTANCE_POINTS[5]:
      return digBg5
    case DISTANCE_POINTS[6]:
      return digBg6
    case DISTANCE_POINTS[7]:
      return digBg7
    case DISTANCE_POINTS[0]:
    default:
      return digBg0
  }
}

export const minerDistance = (distance: number) => {
  switch (distance) {
    case DISTANCE_POINTS[1]:
      return '-18px'
    case DISTANCE_POINTS[2]:
      return '22px'
    case DISTANCE_POINTS[3]:
      return '62px'
    case DISTANCE_POINTS[4]:
      return '100px'
    case DISTANCE_POINTS[5]:
      return '138px'
    case DISTANCE_POINTS[6]:
      return '177px'
    case DISTANCE_POINTS[7]:
      return '252px'
    case DISTANCE_POINTS[0]:
    default:
      return '-65px'
  }
}

export const minerMove = (distance: number) => {
  const currentDistanceIndex = DISTANCE_POINTS.findIndex((i) => i === distance)
  const nextDistanceIndex = currentDistanceIndex + 1
  return DISTANCE_POINTS[nextDistanceIndex] ?? 0
}

export const minerImage = (pickaxes: number, mining: boolean) => {
  if (mining) return minerAnimation
  if (pickaxes === 0) return minerSad
  return minerDefault
}

export interface DemoGameStoreState {
  pickaxes: number
  reward: number
  distance: number
}

export const useDemoGameStore = create<DemoGameStoreState>(() => ({
  pickaxes: 3,
  reward: 0,
  distance: 100
}))
