import { Box, HStack, VStack } from '@styled-system/jsx'
import type { FC } from 'react'

const PlaceholderItem: FC = () => (
  <HStack
    css={{
      w: '100%',
      p: '12px 16px',
      gap: '16px'
    }}>
    <Box
      css={{
        w: '72px',
        h: '72px',
        rounded: '16px',
        bgColor: 'rgba(236, 236, 236, 1)'
      }}
    />
    <VStack
      css={{
        alignItems: 'flex-start'
      }}>
      <Box
        css={{
          w: '77px',
          h: '20px',
          rounded: '2px',
          bgColor: 'rgba(232, 232, 232, 1)'
        }}
      />
      <Box
        css={{
          w: '134px',
          h: '17px',
          rounded: '2px',
          bgColor: 'rgba(243, 243, 243, 1)'
        }}
      />
    </VStack>
  </HStack>
)

export const CommunityListPlaceholder: FC = () => {
  return (
    <VStack>
      <PlaceholderItem />
      <PlaceholderItem />
      <PlaceholderItem />
      <PlaceholderItem />
      <PlaceholderItem />
    </VStack>
  )
}
