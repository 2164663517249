import { useState, type FC } from 'react'
import { TokenHeat } from './token-heat.component'
import avatarPlaceholder from './assets/token/token-avatar-placeholder.svg'
import darkAvatarPlaceholder from './assets/token/token-avatar-placeholder-dark.svg'
import { css, cx } from '@styled-system/css'

const tokenAvatarContainer = css({
  position: 'relative',
  width: '40px',
  height: '40px',
  boxSizing: 'border-box',
  borderRadius: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden'
})

const darkTokenHeatEffect = css({
  filter: `drop-shadow(0px 0px 3px #FD3201)`
})

const tokenAvatarHeat = css({
  position: 'absolute',
  left: 0,
  top: 0
})

const tokenAvatar = css({
  width: '28px',
  height: '28px',
  borderRadius: '28px'
})

export const TokenAvatar: FC<{
  heat: number
  avatar: string
  dark?: boolean
}> = ({ avatar, heat, dark }) => {
  const [avatarSrc, setAvatarSrc] = useState(avatar)
  const [visible, setVisible] = useState(false)

  return (
    <div className={cx(tokenAvatarContainer, dark && heat > 0.85 && darkTokenHeatEffect)}>
      <TokenHeat className={tokenAvatarHeat} heat={heat} />
      <img
        style={{
          opacity: visible ? 1 : 0
        }}
        className={tokenAvatar}
        src={avatarSrc}
        onLoad={() => setVisible(true)}
        onError={() => {
          setAvatarSrc(dark ? darkAvatarPlaceholder : avatarPlaceholder)
          setVisible(true)
        }}
      />
      {/* <div className={styles.tokenAvatar} style={{ backgroundImage: `url('${avatar}')` }} /> */}
    </div>
  )
}
