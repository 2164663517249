import { useEffect } from 'react'

export const usePreloadImages = (images: string[]): void => {
  useEffect(() => {
    images.forEach((i) => {
      const image = new Image()
      image.src = i
    })
  }, [images])
}
