import { Box, Container, VStack } from '@styled-system/jsx'
import type { FC } from 'react'
import notFoundImage from './assets/not-found-screen/404.png'
import { css } from '@styled-system/css'
import { useTranslation } from 'react-i18next'
import { Link } from '@tanstack/react-router'
import { flex } from '@styled-system/patterns'

export const NotFoundScreen: FC = () => {
  const { t } = useTranslation()

  return (
    <Container bgColor="#22232B" h="100vh" p="0">
      <VStack h="100%" justifyContent="center">
        <img
          src={notFoundImage}
          className={css({
            w: '210px',
            marginBottom: '37px'
          })}
        />
        <Box color="rgba(255, 255, 255, 0.40)" fontSize="14px" fontWeight="400" marginBottom="80px">
          {t(`routes.exception.notFound.message`)}
        </Box>
        <Link
          className={flex({
            fontWeight: '590',
            fontSize: '14px',
            color: '#FFFFFF',
            alignItems: 'center',
            justifyContent: 'center',
            w: '150px',
            h: '48px',
            rounded: '8px',
            bgColor: '#575862'
          })}
          to="/">
          {t(`routes.exception.homeButton.label`)}
        </Link>
      </VStack>
    </Container>
  )
}
