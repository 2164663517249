import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { useEffect, useState } from 'react'
import { Configuration } from '@/configuration'
import { enableDebugger } from '@/initializers'
import { captureException } from '@sentry/react'
import { useUserProfileQuery } from '../../hooks'
import type { SolcloudTypes } from '../solcloud'

export * from './useUserAuthentication'

export type NecessaryJwtTokens = Partial<SolcloudTypes['JwtTokens']> &
  Pick<SolcloudTypes['JwtTokens'], 'accessToken' | 'refreshToken'>

export interface AuthenticationState {
  authToken: NecessaryJwtTokens | null
  forbidden: boolean | null
  isInternal: boolean
  isNew: boolean
  setIsNew: (isNew: boolean) => void
  setAuthToken: (authToken: NecessaryJwtTokens | null) => void
  getAuthTokenSync: () => NecessaryJwtTokens | null
  setForbidden: (forbidden: boolean | null) => void
  setIsInterval: (isInterval: boolean) => void
}

const AUTHENTICATION_STORAGE_NAME = 'authentication-storage'

export const useAuthenticationStore = create(
  persist<AuthenticationState, [], [], Pick<AuthenticationState, 'authToken'>>(
    (set) => ({
      authToken: null,
      forbidden: false,
      isInternal: false,
      isNew: false,
      setIsNew: (isNew) => {
        set({
          isNew
        })
      },
      setAuthToken: (authToken): void => {
        try {
          const storageData = JSON.parse(window.localStorage.getItem(AUTHENTICATION_STORAGE_NAME) || '{}')
          window.localStorage.setItem(
            AUTHENTICATION_STORAGE_NAME,
            JSON.stringify({
              ...storageData,
              state: {
                authToken
              }
            })
          )
        } catch (error) {
          console.error(error)
          captureException(error)
        }
        set({ authToken })
      },
      getAuthTokenSync: (): SolcloudTypes['JwtTokens'] | null => {
        try {
          const storageData = JSON.parse(window.localStorage.getItem(AUTHENTICATION_STORAGE_NAME) || '{}')
          return storageData?.state?.authToken ?? null
        } catch (error) {
          console.error(error)
          captureException(error)
        }

        return null
      },
      setForbidden: (forbidden): void => set({ forbidden }),
      setIsInterval: (isInternal): void => set({ isInternal })
    }),
    {
      name: AUTHENTICATION_STORAGE_NAME,
      storage: createJSONStorage(() => localStorage),
      // 只持久化 authToken
      partialize: (state) => ({ authToken: state.authToken })
    }
  )
)

export const useUserProfile = (): void => {
  const setForbidden = useAuthenticationStore((state) => state.setForbidden)
  const setIsInterval = useAuthenticationStore((state) => state.setIsInterval)
  const { profile } = useUserProfileQuery()

  const developer = !!(profile?.flags.developer ?? false)

  // const { data: eapData } = useQuery(solanaQueries.assets.fetchEarlyAccess(profile?.identity_address))

  // useEffect(() => {
  //   if (typeof eapData === 'undefined') return
  //   setForbidden(!eapData)
  // }, [eapData, setForbidden])

  useEffect(() => {
    setIsInterval(developer)
  }, [developer, profile, setForbidden, setIsInterval])

  useEffect(() => {
    if (developer) {
      enableDebugger()
    }
  }, [developer])
}

export const useRefreshTokenEffect = (): boolean => {
  const { getAuthTokenSync } = useAuthenticationStore()
  const [tokenPrepared, setTokenPrepared] = useState(false)

  // Refresh JWT Token
  useEffect(() => {
    const authToken = getAuthTokenSync()
    if (authToken) {
      void tryRefreshJwtToken(authToken, 'prepare').then(() => {
        setTokenPrepared(true)
        return
      })
    } else {
      setTokenPrepared(true)
    }

    const interval = setInterval(async () => {
      const authToken = getAuthTokenSync()
      console.debug('refresh token interval', authToken, tokenPrepared)
      if (!authToken) return
      if (!tokenPrepared) return
      // refresh token when 50% of accessTokenExpireSeconds is left
      const now =
        new Date().getTime() +
        (authToken.accessTokenExpireSeconds ? authToken.accessTokenExpireSeconds * 0.5 : 0) * 1000
      await tryRefreshJwtToken(authToken, 'timer', { now })
    }, 1000 * 31)

    return (): void => clearInterval(interval)
  }, [getAuthTokenSync, tokenPrepared])

  return tokenPrepared
}

type RefreshReason = 'timer' | 'call' | 'prepare' | 'twitter'

interface RefreshTokenOptions {
  now?: number
  force?: boolean
}

export const ensureValidJwtToken = async (opts?: RefreshTokenOptions): Promise<NecessaryJwtTokens | null> => {
  const authToken = useAuthenticationStore.getState().getAuthTokenSync()
  return await tryRefreshJwtToken(authToken, 'call', opts)
}

export const tryRefreshJwtToken = async (
  authToken: NecessaryJwtTokens | null,
  reason: RefreshReason,
  opts?: RefreshTokenOptions
): Promise<NecessaryJwtTokens | null> => {
  const now = opts?.now ?? new Date().getTime()
  const { setAuthToken } = useAuthenticationStore.getState()
  console.debug(`${reason} refresh token`, now, authToken)
  if (!authToken) {
    setAuthToken(null)
    return null
  }

  if (now > (authToken.accessTokenExpiredAt ?? 0) * 1000 || opts?.force) {
    console.debug(
      `[${reason}] refresh tokens before`,
      authToken,
      new Date(now).toLocaleString(),
      new Date((authToken.accessTokenExpiredAt ?? 0) * 1000).toLocaleString()
    )
    const [status, result] = await fetchRefreshToken(authToken)
    console.debug(`[${reason}] refresh tokens after`, status, result)
    if (status === 'success') setAuthToken(result)
    if (status === 'failed') {
      setAuthToken(null)
      return null
    }
    return result
  }

  console.debug(`${reason} result: valid`, authToken)
  return authToken
}

const fetchRefreshToken = async (
  tokens: NecessaryJwtTokens
): Promise<['success', NecessaryJwtTokens] | ['failed', string] | ['error', NecessaryJwtTokens]> => {
  try {
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    const requestOptions: RequestInit = {
      method: 'POST',
      headers,
      body: JSON.stringify({ refreshToken: tokens.refreshToken }),
      redirect: 'follow'
    }

    const resp = await fetch(`${Configuration.SOLCLOUD_API_ENDPOINT}/auth/refreshToken`, requestOptions)
    console.debug('fetch result', resp.status)
    if (resp.status === 200) {
      const data = (await resp.json()) as NecessaryJwtTokens
      return ['success', data]
    }

    const text = await resp.text()
    console.error('refresh token failed', resp.status, text)
    if (resp.status === 401) {
      return ['failed', text]
    }

    return ['error', tokens]
  } catch (error) {
    console.error('refresh token error unknown', error)
    return ['error', tokens]
  }
}
