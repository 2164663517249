export const locales = [
  {
    label: 'English',
    value: 'en-US'
  }
]

export const defaultLocale = {
  label: 'English',
  value: 'en-US'
}

export const resources = {
  'en-US': {
    translation: {
      game: {
        tbs: {
          ui: {
            navbar: {
              worldButton: {
                label: 'world'
              },
              mintButton: {
                label: 'mining'
              },
              workerButton: {
                label: 'worker'
              },
              fightButton: {
                label: 'fight'
              }
            },
            hud: {
              workers: {
                label: 'Worker'
              }
            }
          }
        }
      },
      common: {
        authorization: {
          twitter: {
            failed: {
              message: 'Twitter authentication failed'
            }
          },
          wallet: {
            failed: {
              message: 'Authorization failed. Please try again'
            }
          }
        },
        price: {
          sol: {
            unit: 'SOL'
          }
        },
        modal: {
          closeButton: {
            label: 'Close'
          }
        },
        copyToast: 'Copied',
        failedToast: 'Something was wrong, please try again',
        successToast: 'Succeed',
        infinityLoader: {
          loadingText: 'Loading...',
          noContentText: 'Nothing yet...',
          noMoreText: 'It is the end.'
        },
        searchMode: {
          searchingText: 'Searching community...',
          notFoundText: 'Not found.',
          cancelButton: {
            label: 'Cancel'
          },
          message: {
            notFound: {
              content: 'Not found'
            }
          }
        },
        connectWalletModal: {
          heading: 'Connect wallet',
          message: 'Generate / Import a wallet to continue',
          cancelButton: {
            label: 'Later'
          },
          confirmButton: {
            label: 'Connect'
          }
        },
        navbar: {
          home: {
            label: 'Home'
          },
          ranking: {
            label: 'Ranking'
          },
          trade: {
            label: 'Trade'
          },
          invite: {
            label: 'Invite'
          },
          wallet: {
            label: 'Wallet'
          }
        }
      },
      routes: {
        exception: {
          homeButton: {
            label: 'Home'
          },
          development: {
            // message: 'The server is only open to invited users.'
            message: 'TurbX Beta V1.0.1 testing phase has ended.'
          },
          notFound: {
            message: 'Page not found...'
          },
          error: {
            message: 'Something was error...'
          }
        },
        profile: {
          title: 'My Account',
          connectWalletButton: {
            label: 'Connect wallet'
          },
          point: {
            label: 'Point'
          },
          contactUs: {
            label: 'Contact us',
            modal: {
              heading: 'Contact us'
            }
          }
        },
        signIn: {
          title: 'Sign in',
          heading: 'Welcome to TurbX',
          description: 'Start sharing your crypto journey',
          walletSignInButton: {
            label: 'Connect wallet'
          },
          telegramSignInButton: {
            label: 'Sign in with Telegram'
          },
          agreement: {
            label: 'Clicking the button will be considered as agreeing to the <agreement />'
          },
          agreementLink: {
            label: 'user agreement'
          },
          authorize: {
            heading: 'Connect the wallet',
            description: 'Start sharing your crypto journey',
            connectButton: {
              label: 'Connect wallet'
            },
            successHint: {
              label: 'Please return to <appName /> to complete the connection'
            },
            successHintAppName: {
              label: 'Turbx App'
            }
          }
        },
        index: {
          title: 'Home',
          walletTip: {
            content: 'Your wallet has been generated',
            checkWalletButton: {
              label: 'Check wallet'
            },
            closeButton: {
              label: 'Got it'
            }
          },
          topTokens: {
            marketingCap: {
              label: 'MC'
            }
          }
        },
        pair: {
          '404': {
            message: 'Token not found, please check and try again',
            button: {
              label: 'Back'
            }
          },
          loading: {
            message: 'Loading...'
          },
          comments: {
            heading: 'Comment',
            orderBy: {
              latest: {
                label: 'Latest'
              },
              popular: {
                label: 'Popular'
              }
            },
            sender: {
              button: {
                label: 'Comment'
              },
              heading: 'Leave a comment',
              placeholder: 'Leave a comment...',
              confirmLabel: 'Send',
              cancelLabel: 'Cancel'
            }
          },
          modal: {
            sellConfirm: {
              balance: {
                label: 'Balance'
              },
              cancel: {
                label: 'Cancel'
              },
              confirm: {
                label: 'Confirm Sell'
              }
            },
            sellCustom: {
              balance: {
                label: 'Balance'
              },
              cancel: {
                label: 'Cancel'
              },
              confirm: {
                label: 'Confirm Sell'
              }
            },
            buyConfirm: {
              cancel: {
                label: 'Cancel'
              },
              confirm: {
                label: 'Confirm Buy'
              }
            },
            buyCustom: {
              cancel: {
                label: 'Cancel'
              },
              confirm: {
                label: 'Confirm Buy'
              }
            },
            slippage: {
              cancel: {
                label: 'Cancel'
              },
              confirm: {
                label: 'Confirm'
              }
            },
            priorityFee: {
              cancel: {
                label: 'Cancel'
              },
              confirm: {
                label: 'Confirm'
              }
            }
          },
          detail: {
            marketCap: {
              label: 'MarketCap'
            },
            balance: {
              label: 'Balance'
            },
            renounced: {
              label: 'Renounced'
            },
            burned: {
              label: 'Burned'
            }
          },
          transaction: {
            sent: {
              message: 'Transaction sent'
            },
            success: {
              message: 'Transfer success'
            },
            error: {
              default: `Solana chain congestion`,
              insufficientBalance: 'Insufficient balance'
            },
            failed: {
              message: 'Transaction failed'
            },
            settings: {
              slippage: {
                label: 'Slippage',
                auto: {
                  label: 'Auto'
                }
              },
              priorityFee: {
                label: 'Priority fee',
                custom: {
                  label: 'Custom'
                }
              }
            },
            buy: {
              label: 'Buy',
              custom: {
                label: 'Custom'
              }
            },
            sell: {
              label: 'Sell',
              custom: {
                label: 'Custom'
              }
            },
            tip: `we'll charge 1% from every transaction`
          }
        },
        tokenList: {
          title: 'Tokens',
          tabs: {
            popular: {
              label: 'Popular'
            },
            owned: {
              label: 'My tokens'
            },
            favorite: {
              label: 'Favorites'
            }
          }
        },
        ranking: {
          title: 'Ranking'
        },
        wallet: {
          title: 'Wallet',
          balance: {
            label: 'Total',
            unit: 'SOL'
          },
          addressList: {
            primaryLabel: 'MAIN',
            addAddress: {
              label: 'Add address'
            }
          },
          addressDetail: {
            setMainButton: {
              label: 'Set as main'
            },
            addressNameModal: {
              title: 'Rename wallet',
              cancelButton: {
                label: 'Cancel'
              },
              okButton: {
                label: 'OK'
              }
            },
            addressRemoveModal: {
              title: 'Sure to delete the wallet?',
              content:
                'Confirm that you have transferred all assets of the wallet or saved the private key. Please note that the wallet cannot be restored after being deleted',
              confirmButton: {
                label: 'Delete'
              },
              cancelButton: {
                label: 'Late'
              }
            }
          },
          withdrawButton: {
            label: 'Withdraw'
          },
          depositButton: {
            label: 'Deposit'
          },
          tokenList: {
            label: 'My Assets',
            placeholder: 'No token assets yet'
          },
          addAddress: {
            generateWallet: {
              label: 'Generate a new wallet',
              toast: {
                successMessage: 'a new wallet added',
                failMessage: 'Something was wrong, please try again'
              },
              generateButton: {
                label: 'Generate'
              }
            },
            or: 'OR',
            importPrivateKey: {
              label: 'Input your private key',
              title: 'Your private key',
              placeholder: 'Private key',
              cancelButton: {
                label: 'Cancel'
              },
              importButton: {
                label: 'Import'
              },
              toast: {
                successMessage: 'Import successfully',
                failMessage: 'Something was wrong, please try again'
              }
            }
          },
          withdraw: {
            title: 'Withdraw',
            fromAddress: {
              label: 'From'
            },
            withdrawAddress: {
              label: 'To'
            },
            asset: {
              label: 'Asset'
            },
            amount: {
              label: 'Amount',
              max: {
                label: 'Max',
                tip: 'Max transfer'
              }
            },
            tip: 'Withdraw from TurbX trading wallet to your withdraw wallet. Please keep a balance of 0.0025SOL + priority fee for the withdrawal to be successful.',
            confirmButton: {
              label: 'Confirm'
            }
          },
          withdrawWallet: {
            title: 'Set withdraw wallet',
            changeButton: {
              label: 'Change'
            },
            walletType: {
              label: 'Wallet Type',
              solana: {
                label: 'Solana'
              },
              evm: {
                label: 'EVM'
              }
            },
            walletName: {
              label: 'Wallet Name',
              placeholder: 'Wallet name'
            },
            walletAddress: {
              label: 'Enter Wallet Address',
              placeholder: 'Wallet address'
            },
            confirmButton: {
              label: 'Confirm'
            }
          },
          deposit: {
            title: 'Deposit',
            copyButton: {
              label: 'Copy'
            },
            hint: 'This address can only be used to receive compatible tokens.',
            selector: {
              label: 'Deposit to'
            }
          },
          selector: {
            address: {
              label: 'Select address',
              balanceLabel: 'balance'
            },
            token: {
              label: 'Select assets',
              balanceLabel: 'balance'
            }
          }
        },
        quest: {
          invite: {
            title: 'Invite friends',
            description: 'You and your friend will receive bonuses',
            friendList: {
              label: 'Friend List'
            },
            inviteMoreFriends: {
              label: `Invite {{limit}} friends`,
              award: '+5,000',
              target: {
                label: 'for you and your friend'
              }
            },
            hint: `You haven't invited friends yet`,
            buttons: {
              invite: {
                label: 'Invite Friends'
              },
              copyLink: {
                label: 'Copy Invite Link'
              }
            }
          }
        },
        community: {
          myCommunity: {
            header: {
              label: 'My Community'
            }
          },
          chatroom: {
            satisfied: {
              message: 'You can join the community now!',
              backButton: {
                label: 'Back'
              },
              joinButton: {
                label: 'Join'
              }
            },
            notSatisfied: {
              message: 'Please ensure that you have {{token}} (token) in your wallet and at least {{balance}} coins',
              walletButton: {
                label: 'Go wallet'
              },
              tradeButton: {
                label: 'Trade it'
              }
            },
            input: {
              placeholder: 'Say something...',
              sendButton: {
                label: 'Send'
              }
            },
            message: {
              contextmenu: {
                copy: {
                  label: 'Copy',
                  toast: 'Message copied'
                },
                reply: {
                  label: 'Reply'
                }
              }
            }
          }
        }
      }
    }
  }
}
