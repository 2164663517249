import { useState, type FC } from 'react'
import { Icon } from '@turbx-libs/design-system/components'
import { Trans, useTranslation } from 'react-i18next'
import { RuntimeType, useOSStore, useUserAuthentication } from '@/stores'
import { Modal } from './modal/modal.component'
import { useModal } from './modal/modal.hook'
import { html } from '@/assets/docs/term_of_use.md'
import { Box, Container, Float, HStack, VStack } from '@styled-system/jsx'
import { css, cx } from '@styled-system/css'
import { vstack } from '@styled-system/patterns'
import { captureException } from '@sentry/react'

import bgEffectImage from './assets/sign-in/bg-effect.png'
import bgEffectDesktopImage from './assets/sign-in/bg-effect-desktop.png'
import connectImage from './assets/sign-in/connect.png'
import { TelegramSignInButton } from './telegram-sign-in-button.component'

export const SignIn: FC = () => {
  const { t } = useTranslation()

  const agreementModal = useModal()

  const [isPending, setIsPending] = useState(false)
  const { signIn } = useUserAuthentication()
  const runtime = useOSStore((state) => state.runtime)

  return (
    <>
      <Modal modalId={agreementModal.modalId}>
        <Box
          css={{
            w: '90vw',
            h: '80vh',
            mx: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <Box
            css={{
              h: '100%',
              pos: 'relative'
            }}>
            <div
              className={cx(
                'markdown',
                css({
                  flex: '1 1 0',
                  h: '100%',
                  overflow: 'auto',
                  scrollbar: 'hidden'
                })
              )}
              dangerouslySetInnerHTML={{ __html: html }}
            />
            <Float offset="48px">
              <Icon
                onClick={() => agreementModal.close()}
                name="close"
                className={css({
                  w: '24px',
                  h: '24px'
                })}
              />
            </Float>
          </Box>
        </Box>
      </Modal>

      <Container
        className={vstack({
          pos: 'relative',
          minH: '100svh',
          bg: `linear-gradient(0deg, rgba(182, 46, 255, 1), rgba(74, 28, 153, 1))`,
          p: 0,
          gap: 0
        })}>
        <img
          className={css({
            pos: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            hideBelow: 'md'
          })}
          src={bgEffectDesktopImage}
        />
        <img
          className={css({
            pos: 'absolute',
            top: '-54px',
            right: 0,
            left: '12px',
            h: '472px',
            hideFrom: 'md'
          })}
          src={bgEffectImage}
        />
        <VStack
          css={{
            zIndex: 1,
            flex: 1,
            h: '100%',
            justifyContent: 'flex-end',
            paddingBottom: '20px',
            md: {
              justifyContent: 'center'
            }
          }}>
          <VStack
            css={{
              p: '48px 32px',
              rounded: '24px',
              bg: '#FFF',
              boxShadow: '0px 12px 32px 0px rgba(0, 0, 0, 0.12)',
              marginInline: '12px',
              gap: '24px',
              md: {
                w: '366px'
              }
            }}>
            <Box>
              <h1
                className={css({
                  color: '#000',
                  fontSize: '32px',
                  lineHeight: '38.19px',
                  fontWeight: 590
                })}>
                {t(`routes.signIn.heading`)}
              </h1>
              <span
                className={css({
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '16.71px',
                  color: 'rgba(0, 0, 0, 0.5)'
                })}>
                {t(`routes.signIn.description`)}
              </span>
            </Box>
            <img
              src={connectImage}
              className={css({
                w: '210px'
              })}
            />
            {runtime === RuntimeType.TMA || import.meta.env.DEV ? (
              <HStack
                css={{
                  h: '54px',
                  w: '100%',
                  justifyContent: 'center',
                  rounded: '8px',
                  bgColor: 'rgba(129, 22, 195, 1)',
                  color: '#FFF',
                  fontSize: '16px',
                  fontWeight: 590,
                  cursor: 'pointer'
                }}
                onClick={async () => {
                  if (isPending) return
                  setIsPending(true)
                  try {
                    await signIn()
                  } catch (error) {
                    captureException(error)
                    console.error(error)
                  }
                  setIsPending(false)
                }}>
                {t(`routes.signIn.telegramSignInButton.label`)}
              </HStack>
            ) : (
              <TelegramSignInButton onSignIn={signIn} />
            )}
            <span
              className={css({
                color: 'rgba(0, 0, 0, 0.4)',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '16.71px'
              })}>
              <Trans
                t={t}
                i18nKey={`routes.signIn.agreement.label`}
                components={{
                  agreement: (
                    <Box
                      onClick={() => agreementModal.open()}
                      className={css({
                        display: 'inline',
                        textDecoration: 'underline',
                        cursor: 'pointer'
                      })}>
                      {t(`routes.signIn.agreementLink.label`)}
                    </Box>
                  )
                }}
              />
            </span>
          </VStack>
        </VStack>
      </Container>
    </>
  )
}
