import { createElement, type FC, type HTMLAttributes, type PropsWithChildren } from 'react'
import { css, cx } from '@styled-system/css'
import { Box } from '@styled-system/jsx'

export const pressable = css({
  transitionProperty: 'background',
  transitionDuration: 'fast',
  transitionTimingFunction: 'ease-in-out',
  cursor: 'pointer',
  _hover: {
    bg: 'hover'
  },
  _pressed: {
    bg: 'pressed'
  }
})

export interface PressableProps extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  pressableClassName?: string
}

export const Pressable: FC<PressableProps> = ({ children, className, pressableClassName, ...props }) => {
  return (
    <Box
      className={className}
      css={{
        pos: 'relative'
      }}>
      {createElement('div', {
        ...props,
        className: cx(
          css({
            pos: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }),
          pressable,
          pressableClassName
        )
      })}
      {children}
    </Box>
  )
}
