import { css, cx } from '@styled-system/css'
import { Avatar } from '@turbx-libs/design-system/components'
import type { CSSProperties, FC } from 'react'

export interface UserAvatarProps {
  size: number
  src: string | undefined | null
  address: string
  className?: string
  style?: CSSProperties
}

export const UserAvatar: FC<UserAvatarProps> = ({ size, src, address, className, style }) => {
  return src ? (
    <img
      className={cx(css({}), className)}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        ...style
      }}
      src={src}
    />
  ) : (
    <Avatar square={false} size={size} name={address} className={className} />
  )
}
