import { Box, Divider, HStack, VStack } from '@styled-system/jsx'
import { useMemo, type FC } from 'react'
import { css } from '@styled-system/css'
import { Icon, Pressable } from '@turbx-libs/design-system/components'
import { levelImage, type SolcloudTypes } from '@/stores/solcloud'
import { createDataset, numberAbbr } from '@/utils'
import { useTranslation } from 'react-i18next'
import { CommunityAvatar, PriceIncrease, UserAvatar, useCommunityAvatar } from '@/components'
import { Link } from '@tanstack/react-router'
import type { TokenSource } from '@turbx-libs/solcommon'

export interface CommunityCardProps {
  token: SolcloudTypes['MaybeCommunityType']['token']
  community: SolcloudTypes['MaybeCommunityType']['community']
  priceItem: SolcloudTypes['PriceItem'] | undefined
}

const sourceColor = (source: TokenSource) => {
  switch (source) {
    case 'pumpfun':
      return {
        backgroundColor: 'rgba(225, 234, 255, 1)',
        color: 'rgba(99, 120, 173, 1)'
      }
    case 'moonshot':
      return {
        backgroundColor: 'rgba(249, 240, 215, 1)',
        color: 'rgba(175, 84, 34, 1)'
      }
    case 'unknown':
    default:
      return {
        backgroundColor: 'rgba(245, 234, 255, 1)',
        color: 'rgba(139, 99, 176, 1)'
      }
  }
}

export const CommunityCard: FC<CommunityCardProps> = ({ token, community, priceItem }) => {
  const { t } = useTranslation()

  const latestMessages = useMemo(() => community?.latestMessages.slice(0, 2), [community?.latestMessages])
  const communityAvatar = useCommunityAvatar(token.logo)

  return (
    <Link to="/communities/$mint" params={{ mint: token.mint }}>
      {({ isActive }) => {
        return (
          <Pressable>
            {/*  desktop version */}
            <HStack
              {...createDataset({
                'data-active': isActive
              })}
              hideBelow="md"
              css={{
                gap: '16px',
                p: '12px 16px',
                _active: {
                  bgColor: 'rgba(0, 0, 0, 0.04)',
                  borderLeft: '2px solid rgba(129, 22, 195, 1)',
                  rounded: '4px'
                }
              }}>
              <Box
                css={{
                  w: '64px',
                  h: '64px',
                  pos: 'relative'
                }}>
                <CommunityAvatar
                  src={communityAvatar}
                  className={css({
                    w: '64px',
                    h: '64px',
                    rounded: '16px',
                    border: '1px solid rgba(0, 0, 0, 0.16)'
                  })}
                />
                {community?.level && (
                  <img
                    className={css({
                      w: '28px',
                      pos: 'absolute',
                      right: '-10px',
                      bottom: '-10px'
                    })}
                    src={levelImage(community?.level)}
                  />
                )}
              </Box>
              <VStack
                css={{
                  alignItems: 'flex-start',
                  flex: 1
                }}>
                <Box
                  css={{
                    fontWeight: 510,
                    fontSize: '14px',
                    lineHeight: '16.71px'
                  }}>
                  {token.name}
                </Box>
                <HStack>
                  {priceItem?.priceChange24h && (
                    <PriceIncrease value={Number(Number(priceItem.priceChange24h).toFixed(3))} />
                  )}
                  {token.source && (
                    <HStack
                      style={sourceColor(token.source)}
                      css={{
                        rounded: '4px',
                        p: '4px 8px',
                        h: '22px',
                        fontWeight: 510,
                        fontSize: '12px',
                        lineHeight: '14.32px'
                      }}>
                      {token.source}
                    </HStack>
                  )}
                </HStack>
                <Box css={{ fontWeight: 400, fontSize: '12px', lineHeight: '14.32px', color: 'rgba(144, 67, 0, 0.7)' }}>
                  {t('routes.index.topTokens.marketingCap.label')}{' '}
                  <Box css={{ fontWeight: 590, display: 'inline' }}>
                    {priceItem?.marketCap ? `$${numberAbbr(priceItem.marketCap)}` : '-'}
                  </Box>
                </Box>
              </VStack>
              {
                <Box
                  css={{
                    bgColor: 'rgba(0, 0, 0, 0.04)',
                    rounded: '39px',
                    p: '4px 10px',
                    fontSize: '12px',
                    color: 'rgba(41, 41, 41, 1)'
                  }}>
                  {community?.primary_message_count ?? 0}
                </Box>
              }
            </HStack>
            {/*  mobile version */}
            <VStack
              hideFrom="md"
              css={{
                w: '100%',
                bgColor: '#FFF',
                rounded: '12px',
                border: '1px solid rgba(0, 0, 0, 0.08)',
                overflow: 'hidden'
              }}>
              <Box
                css={{
                  h: '154px',
                  w: '100%',
                  pos: 'relative',
                  bgPosition: 'center',
                  bgSize: 'cover'
                }}
                style={{
                  backgroundImage: `url('${communityAvatar}')`
                }}>
                {community && (
                  <HStack
                    css={{
                      pos: 'absolute',
                      top: '6px',
                      right: '6px',
                      bgColor: 'rgba(0, 0, 0, 0.7)',
                      rounded: '34px',
                      p: '4px 8px',
                      boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.25)',
                      color: '#FFF',
                      fontWeight: 400,
                      fontSize: '12px',
                      lineHeight: '14.32px',
                      gap: '8px'
                    }}>
                    <HStack css={{ gap: '2px' }}>
                      <Icon
                        name="group-line"
                        className={css({ w: '12px', h: '12px', color: 'rgba(255, 255, 255, 0.4)' })}
                      />
                      {numberAbbr(community.total_users_count)}
                    </HStack>
                    <Divider orientation="vertical" color="rgba(255, 255, 255, 0.3)" css={{ h: '10px' }} />
                    <HStack css={{ gap: '2px' }}>
                      <Icon
                        name="question-answer-line"
                        className={css({ w: '12px', h: '12px', color: 'rgba(255, 255, 255, 0.4)' })}
                      />
                      {numberAbbr(community.primary_message_count)}
                    </HStack>
                  </HStack>
                )}
                <VStack
                  css={{
                    pos: 'absolute',
                    left: '4px',
                    bottom: '6px',
                    gap: '4px',
                    alignItems: 'flex-start'
                  }}>
                  {latestMessages?.map((m) => (
                    <HStack
                      key={m.id}
                      css={{
                        bgColor: 'rgba(0, 0, 0, 0.3)',
                        backdropFilter: 'blur(8px)',
                        rounded: '34px',
                        p: '4px',
                        gap: '4px',
                        color: '#FFF',
                        fontSize: '12px',
                        fontWeight: 400,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxW: '140px'
                      }}>
                      <UserAvatar size={16} src={m.account.avatar?.viewUrl} address={m.account.primary_address} />
                      {m.payload.type === 'Text' ? m.payload.data.body : ''}
                    </HStack>
                  ))}
                </VStack>
              </Box>
              <VStack css={{ p: '12px', alignItems: 'flex-start', gap: '10px', w: '100%', pos: 'relative' }}>
                <Box
                  css={{
                    fontWeight: 590,
                    fontSize: '14px',
                    lineHeight: '16.71px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textWrap: 'nowrap',
                    maxW: '80%',
                    w: '130px'
                  }}>
                  {token.name || token.symbol}
                </Box>
                {community?.level && (
                  <img
                    className={css({
                      w: '50px',
                      pos: 'absolute',
                      right: '2px',
                      top: '-20px'
                    })}
                    src={levelImage(community?.level)}
                  />
                )}
                <Box css={{ fontWeight: 400, fontSize: '12px', lineHeight: '14.32px', color: 'rgba(144, 67, 0, 0.7)' }}>
                  {t('routes.index.topTokens.marketingCap.label')}{' '}
                  <Box css={{ fontWeight: 590, display: 'inline' }}>
                    {priceItem?.marketCap ? `$${numberAbbr(priceItem.marketCap)}` : '-'}
                  </Box>
                </Box>
                <HStack
                  css={{
                    minH: '22px'
                  }}>
                  {priceItem?.priceChange24h && (
                    <PriceIncrease value={Number(Number(priceItem.priceChange24h).toFixed(3))} />
                  )}
                  {token.source && (
                    <HStack
                      style={sourceColor(token.source)}
                      css={{
                        rounded: '4px',
                        p: '4px 8px',
                        h: '22px',
                        fontWeight: 510,
                        fontSize: '12px',
                        lineHeight: '14.32px'
                      }}>
                      {token.source}
                    </HStack>
                  )}
                </HStack>
                {/* <Divider color="rgba(0, 0, 0, 0.1)" thickness="0.5px" /> */}
                {/* <HStack>
          <HStack css={{ gap: '0', justifyContent: 'space-between' }}>
            <Box css={{ bgColor: '#000', border: '1px solid #FFF', w: '20px', h: '20px', rounded: '46px' }} />
            <Box
              css={{
                bgColor: '#000',
                border: '1px solid #FFF',
                w: '20px',
                h: '20px',
                rounded: '46px',
                marginLeft: '-4px'
              }}
            />
            <Box
              css={{
                bgColor: '#000',
                border: '1px solid #FFF',
                w: '20px',
                h: '20px',
                rounded: '46px',
                marginLeft: '-4px'
              }}
            />
          </HStack>
          <Box css={{ color: 'rgba(0, 0, 0, 0.4)', fontSize: '12px', fontWeight: 400, lineHeight: '14.32px' }}>
            294 Holders
          </Box>
        </HStack> */}
              </VStack>
            </VStack>
          </Pressable>
        )
      }}
    </Link>
  )
}
