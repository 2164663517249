import { useEffect, useState, type FC } from 'react'
import defaultAvatar from '@/assets/community/default-avatar.png'

export interface CommunityAvatarProps extends Omit<Partial<HTMLImageElement>, 'src'> {
  src: string | null | undefined
}

export const CommunityAvatar: FC<CommunityAvatarProps> = ({ src, crossOrigin, ...rest }) => {
  const [avatarSrc, setAvatarSrc] = useState(src)
  const [visible, setVisible] = useState(false)
  useEffect(() => {
    setAvatarSrc(src)
  }, [src])

  return (
    <img
      {...(rest as object)}
      style={{
        opacity: visible ? 1 : 0
      }}
      src={avatarSrc ?? ''}
      onLoad={() => setVisible(true)}
      onError={() => {
        setAvatarSrc(defaultAvatar)
        setVisible(true)
      }}
    />
  )
}
