import { css, cx } from '@styled-system/css'
import { motion } from '@turbx-libs/design-system/motion'
import type { CSSProperties, FC, MouseEventHandler, PropsWithChildren } from 'react'

export interface BackdropProps {
  className?: string
  style?: CSSProperties
  onClick?: MouseEventHandler<HTMLDivElement>
}

export const Backdrop: FC<PropsWithChildren<BackdropProps>> = ({ children, className, style, onClick }) => {
  return (
    <motion.div
      style={style}
      className={cx(
        css({
          pos: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          bgColor: 'rgba(0, 0, 0, 0.40)',
          zIndex: 999
        }),
        className
      )}
      onClick={onClick}
      initial={{ opacity: 0, display: 'none' }}
      animate={{ opacity: 1, display: 'block' }}
      exit={{ opacity: 0, display: 'none' }}>
      {children}
    </motion.div>
  )
}
