import { css, cx } from '@styled-system/css'
import { Box, Float, HStack, VStack, Wrap } from '@styled-system/jsx'
import { divider } from '@styled-system/patterns'
import { createDataset } from '@/utils'
import { animate, motion, useMotionValue } from '@turbx-libs/design-system/motion'
import { forwardRef, useEffect, type FC, type PropsWithChildren } from 'react'
import { create } from 'zustand'

export interface TabItem {
  id: string
  label: string
}

export interface TabsProps {
  className?: string
  defaultTab?: string
  items: TabItem[]
}

export interface TabContentProps {
  className?: string
  tabId: string
}

const useTabsStore = create<{ currentTabIndex: number; setCurrentTab: (currentTabIndex: number) => void }>((set) => ({
  currentTabIndex: 0,
  setCurrentTab: (currentTabIndex: number): void =>
    set({
      currentTabIndex
    })
}))

export const TabContent = forwardRef<HTMLDivElement, PropsWithChildren<TabContentProps>>(
  ({ className, children, tabId }, ref) => {
    return (
      <Box ref={ref} px="24px" w="100%" id={tabId} className={className}>
        {children}
      </Box>
    )
  }
)

export const Tabs: FC<PropsWithChildren<TabsProps>> = ({ items, defaultTab, className, children }) => {
  const currentTabIndex = useTabsStore((state) => state.currentTabIndex)
  const setCurrentTab = useTabsStore((state) => state.setCurrentTab)

  const x = useMotionValue(0)

  useEffect(() => {
    animate(x, (-100 * currentTabIndex) / items.length)
  }, [currentTabIndex, items.length, x])

  useEffect(() => {
    let ensureDefaultTabIndex = items.findIndex((i) => i.id === defaultTab)
    ensureDefaultTabIndex = ensureDefaultTabIndex < 0 ? 0 : ensureDefaultTabIndex
    if (ensureDefaultTabIndex && !currentTabIndex) setCurrentTab(ensureDefaultTabIndex)
  }, [defaultTab, currentTabIndex, items, setCurrentTab])

  return (
    <VStack className={cx(css({ bg: '#2E2F38', roundedTop: '24px' }), className)} gap="0" overflow="hidden">
      <HStack w="100%">
        <Wrap
          className={divider({ orientation: 'horizontal', thickness: '0.5px', color: 'rgba(255, 255, 255, 0.1)' })}
          flex="1"
          gap="20px"
          px="24px">
          {items.map((item, index) => (
            <Box
              onClick={() => setCurrentTab(index)}
              py="16px"
              key={item.id}
              fontSize="16px"
              fontWeight="400"
              pos="relative"
              {...createDataset({
                ['data-active']: currentTabIndex === index
              })}
              cursor="pointer"
              css={{
                color: 'rgba(255, 255, 255, 0.5)',
                _active: {
                  color: '#FFFFFF'
                }
              }}>
              {item.label}
              {currentTabIndex === index && (
                <Float placement="bottom-center" offsetY="2px" w="100%">
                  <motion.div
                    className={divider({
                      color: 'rgba(173, 255, 38, 1)',
                      thickness: '4px',
                      orientation: 'horizontal'
                    })}
                    layoutId="underline"
                  />
                </Float>
              )}
            </Box>
          ))}
        </Wrap>
      </HStack>
      <HStack
        flex="1"
        gap="0"
        h="100%"
        alignSelf="flex-start"
        alignItems="stretch"
        style={{
          width: `${100 * items.length}%`,
          transform: `translateX(${(-100 * currentTabIndex) / items.length}%)`
        }}
        transition="all ease 500ms">
        {children}
      </HStack>
    </VStack>
  )
}
