import { hc } from 'hono/client'
import type { SolcoreAppType } from '@turbx-libs/solcore'
import { Configuration } from '@/configuration'
// import { ensureValidJwtToken } from '../authentication'

const buildCommonHeaders = (): Record<string, string> => {
  return {
    'x-app-version': Configuration.APP_VERSION,
    'x-app-source': 'webapp'
  }
}

export const solcoreClient = hc<SolcoreAppType>(Configuration.SOLCORE_API_ENDPOINT, {
  headers: async () => {
    // const token = await ensureValidJwtToken()

    return {
      // ...(token ? { authorization: `Bearer ${token.accessToken}` } : {}),
      ...buildCommonHeaders()
    }
  }
})
