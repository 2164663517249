import { useOSStore } from '@/stores'
import { token } from '@styled-system/tokens'
import { useEffect } from 'react'
import { create } from 'zustand'

export const useNavbarStore = create<{
  visible: boolean
  show: () => void
  hide: () => void
}>((set) => ({
  visible: true,
  show: (): void => set({ visible: true }),
  hide: (): void => set({ visible: false })
}))

// 在 sm breakpoint 时隐藏 navbar
export const useNavbarSmHidden = () => {
  const hide = useNavbarStore((state) => state.hide)
  const show = useNavbarStore((state) => state.show)
  const screenWidth = useOSStore((state) => state.screenDimensions.width)
  const sm = Number(token('breakpoints.sm')?.match(/\d+/)?.[0])

  useEffect(() => {
    if (!sm || screenWidth < sm) hide()
    return () => show()
  }, [hide, screenWidth, show, sm])
}
