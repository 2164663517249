import { useCallback, useEffect, useMemo } from 'react'
import { create } from 'zustand'

interface ModalStoreState {
  config: Record<string, boolean | undefined>
  payload: Record<string, unknown>
  isVisible: (modalId?: string) => boolean
  register: () => string
  unregister: (modalId: string) => void
  open: (modalId: string, payload?: unknown) => void
  close: (modalId: string) => void
}

let counter = 0

export const useModalStore = create<ModalStoreState>((set, get) => ({
  config: {},
  payload: {},
  isVisible: (modalId): boolean => {
    if (modalId) return !!get().config[modalId]
    return Object.values(get().config ?? {}).some((v) => v)
  },
  register: (): string => {
    const modalId = counter.toString()
    counter += 1
    set((state) => ({
      ...state,
      config: {
        ...state.config,
        [modalId]: false
      }
    }))
    return modalId
  },
  unregister: (modalId): void =>
    set((state) => ({
      ...state,
      config: {
        ...state.config,
        [modalId]: undefined
      }
    })),
  open: (modalId, payload?: unknown): void =>
    set((state) => ({
      ...state,
      config: {
        ...state.config,
        [modalId]: true
      },
      payload: {
        ...state.payload,
        [modalId]: payload
      }
    })),
  close: (modalId): void =>
    set((state) => ({
      ...state,
      config: {
        ...state.config,
        [modalId]: false
      }
    }))
}))

export const useModal = (
  modalId?: string
): {
  modalId: string
  payload?: unknown
  open: (payload?: unknown) => void
  close: () => void
  unregister: () => void
  isVisible: () => boolean
} => {
  const register = useModalStore((state) => state.register)
  const unregister = useModalStore((state) => state.unregister)
  const close = useModalStore((state) => state.close)
  const open = useModalStore((state) => state.open)
  const payload = useModalStore((state) => state.payload)

  const currentModalId = useMemo(() => modalId ?? register(), [modalId, register])
  useEffect(() => (): void => unregister(currentModalId), [currentModalId, unregister])

  const handleOpen = useCallback((payload?: unknown) => open(currentModalId, payload), [open, currentModalId])
  const handleClose = useCallback(() => close(currentModalId), [currentModalId, close])
  const handleUnregister = useCallback(() => unregister(currentModalId), [unregister, currentModalId])

  const isVisible = useCallback(() => useModalStore.getState().isVisible(modalId), [modalId])

  return {
    modalId: currentModalId,
    payload: payload[currentModalId] ?? {},
    open: handleOpen,
    close: handleClose,
    unregister: handleUnregister,
    isVisible
  }
}
