import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'
import packageJson from '../package.json'
import { clusterApiUrl } from '@solana/web3.js'

type EnvironmentMode = 'development' | 'staging' | 'production'

interface EnvironmentVariables {
  MODE: EnvironmentMode
  ENABLE_DEBUGGER?: boolean
  SOLCORE_API_ENDPOINT: string
  SOLCLOUD_API_ENDPOINT: string
  SOLANA_FRONTEND_HTTP_ENDPOINT: string
  SENTRY_DSN?: string
  LAMPORTS_PER_SOL: number
  APP_VERSION: string
  FEATURE_FLAGS: string[]
  FEATURE_WALLET_ENABLED: boolean
  WEBAPP_TELEGRAM_BOT_NAME: string
}

const BASE: Pick<
  EnvironmentVariables,
  | 'MODE'
  | 'APP_VERSION'
  | 'FEATURE_FLAGS'
  | 'FEATURE_WALLET_ENABLED'
  | 'LAMPORTS_PER_SOL'
  | 'SOLANA_FRONTEND_HTTP_ENDPOINT'
> = {
  MODE: import.meta.env.MODE as EnvironmentMode,
  SOLANA_FRONTEND_HTTP_ENDPOINT: clusterApiUrl(WalletAdapterNetwork.Mainnet),
  LAMPORTS_PER_SOL: 1000000000,
  APP_VERSION: packageJson.version,
  FEATURE_FLAGS: [],
  FEATURE_WALLET_ENABLED: false
} as const

const CONF: {
  development: EnvironmentVariables
  staging: EnvironmentVariables
  production: EnvironmentVariables
} = {
  // TODO: https://github.com/turbx-labs/turbx/blob/main/apps/@turbx-sol/server/soltg/src/prefetch/index.ts#L10
  development: {
    ...BASE,
    SOLCORE_API_ENDPOINT: 'http://localhost:12311',
    SOLCLOUD_API_ENDPOINT: 'http://localhost:12313',
    WEBAPP_TELEGRAM_BOT_NAME: 'toadfans_bot'
  },
  staging: {
    ...BASE,
    ENABLE_DEBUGGER: true,
    SOLCORE_API_ENDPOINT: 'https://solcore-staging.turbx.io',
    SOLCLOUD_API_ENDPOINT: 'https://solcloud-staging.turbx.io',
    WEBAPP_TELEGRAM_BOT_NAME: 'turbx_bot'
  },
  production: {
    ...BASE,
    ENABLE_DEBUGGER: true,
    SOLCORE_API_ENDPOINT: 'https://solcore.turbx.io',
    SOLCLOUD_API_ENDPOINT: 'https://solcloud.turbx.io',
    SENTRY_DSN: 'https://a2193f589ce799ca53e39815db0c2326@o4507191313235968.ingest.us.sentry.io/4507191316512768',
    WEBAPP_TELEGRAM_BOT_NAME: 'turbx_sol_bot'
  }
}

const fetchConfiguration = () => {
  const mode = import.meta.env.MODE as EnvironmentMode

  if (import.meta.env.DEV)
    return (Object.keys(CONF[mode]) as Array<keyof EnvironmentVariables>).reduce((prev, cur) => {
      return {
        ...prev,
        [cur]: import.meta.env[`VITE_${cur}`] ?? CONF[mode][cur]
      }
    }, {}) as EnvironmentVariables

  return CONF[mode]
}

export const Configuration = fetchConfiguration()
