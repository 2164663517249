export const numberAbbr = (value: number): string => {
  const K = 1000
  const M = K * K
  const B = M * K

  if (value < K) return value.toFixed(2)
  if (value < M) return `${(value / K).toFixed(2)}k`
  if (value < B) return `${(value / M).toFixed(2)}m`
  return `${(value / B).toFixed(2)}b`
}

export const intAbbr = (value: number): string => {
  const K = 1000
  const M = K * K
  const B = M * K

  if (value < K) return value.toString()
  if (value < M) return `${(value / K).toFixed(2)}k`
  if (value < B) return `${(value / M).toFixed(2)}m`
  return `${(value / B).toFixed(2)}b`
}

export const capitalize = (str: string): string =>
  str
    .toLowerCase()
    .split(' ')
    .map((word) => word[0]?.toUpperCase() + word.substring(1))
    .join(' ')

/**
 * Convert a date to a relative time string, such as
 * "a minute ago", "in 2 hours", "yesterday", "3 months ago", etc.
 * using Intl.RelativeTimeFormat
 */
export function relativeTime(
  date: Date | number,
  // lang = navigator.language
  lang = 'en'
): string {
  // Allow dates or times to be passed
  const timeMs = typeof date === 'number' ? date : date.getTime()

  // Get the amount of seconds between the given date and now
  const deltaSeconds = Math.round((timeMs - Date.now()) / 1000)

  // Array representing one minute, hour, day, week, month, etc in seconds
  const cutoffs = [60, 3600, 86400, 86400 * 7, 86400 * 30, 86400 * 365, Infinity]

  // Array equivalent to the above but in the string representation of the units
  const units: Intl.RelativeTimeFormatUnit[] = ['second', 'minute', 'hour', 'day', 'week', 'month', 'year']

  // Grab the ideal cutoff unit
  const unitIndex = cutoffs.findIndex((cutoff) => cutoff > Math.abs(deltaSeconds))

  // Get the divisor to divide from the seconds. E.g. if our unit is "day" our divisor
  // is one day in seconds, so we can divide our seconds by this to get the # of days
  const divisor = unitIndex ? cutoffs[unitIndex - 1]! : 1

  // Intl.RelativeTimeFormat do its magic
  const rtf = new Intl.RelativeTimeFormat(lang, { numeric: 'auto' })
  return rtf.format(Math.floor(deltaSeconds / divisor), units[unitIndex]!)
}

export const masked = (name: string) => {
  const mask = '.'
  return name.substring(0, 4) + mask.repeat(3) + name.slice(-4)
}
