import { create } from 'zustand'

interface NotificationStoreProps {
  isLoading: boolean
  isVisible: boolean
  message: string
  alert: (message: string) => void
  loading: () => void
  disposeLoading: () => void
}

export const useNotificationStore = create<NotificationStoreProps>((set) => ({
  isLoading: false,
  isVisible: false,
  message: '',
  loading: () => {
    set({ isLoading: true })
  },
  disposeLoading: () => {
    set({ isLoading: false })
  },
  alert: (message): void => {
    set({ isVisible: true, message })
  }
}))
