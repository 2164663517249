import levelSPlusImage from '@/assets/community/level/S+.png'
import levelSImage from '@/assets/community/level/S.png'
import levelAPlusImage from '@/assets/community/level/A+.png'
import levelAImage from '@/assets/community/level/A.png'
import levelBImage from '@/assets/community/level/B.png'
import type { SolcloudTypes } from './client'

export const levelImage = (level: SolcloudTypes['CommunityType']['community']['level'] | undefined) => {
  switch (level) {
    case 'S+':
      return levelSPlusImage
    case 'S':
      return levelSImage
    case 'A+':
      return levelAPlusImage
    case 'A':
      return levelAImage
    case 'B':
      return levelBImage
  }
}
