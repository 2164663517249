import { useOSStore } from '@/stores'
import { token } from '@styled-system/tokens'

export const hsb2hsl = (h: number, s: number, b: number) => {
  const x = ((200 - s) * b) / 100
  return [h, x === 0 || x === 200 ? 0 : Math.round((s * b) / (x <= 100 ? x : 200 - x)), Math.round(x / 2)] as const
}

export const hsl2hex = (h: number, s: number, l: number) => {
  l /= 100
  const a = (s * Math.min(l, 1 - l)) / 100
  const f = (n: number) => {
    const k = (n + h / 30) % 12
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0') // convert to Hex and prefix "0" if needed
  }
  return `#${f(0)}${f(8)}${f(4)}`
}

export const hsb2hex = (h: number, s: number, b: number) => hsl2hex(...hsb2hsl(h, s, b))

export const useBreakpoint = (breakpoint: 'sm' | 'md' | 'lg') => {
  const screenWidth = useOSStore((state) => state.screenDimensions.width)
  const breakpointSize = Number(token(`breakpoints.${breakpoint}`).match(/\d+/)?.[0] ?? 0)

  return screenWidth >= breakpointSize
}
