import { css } from '@styled-system/css'
import { HStack } from '@styled-system/jsx'
import type { FC } from 'react'

import increaseImage from '@/assets/price/increase.png'
import decreaseImage from '@/assets/price/decrease.png'

export interface PriceIncreaseProps {
  value: number
}

export const priceBoxHeight = 22

export const PriceIncrease: FC<PriceIncreaseProps> = ({ value }) => {
  return (
    <HStack
      css={{
        rounded: '4px',
        p: '1px 6px 1px 4px',
        h: '22px',
        fontWeight: 510,
        fontSize: '10px',
        lineHeight: '11.93px',
        gap: 0
      }}
      style={{
        backgroundColor: value > 0 ? 'rgba(229, 255, 225, 1)' : 'rgba(255, 239, 236, 1)',
        color: value > 0 ? 'rgba(35, 105, 47, 1)' : 'rgba(190, 63, 63, 1)'
      }}>
      <img
        className={css({
          w: '16px',
          h: '16px'
        })}
        src={value > 0 ? increaseImage : decreaseImage}
      />
      {value.toFixed(2)}%
    </HStack>
  )
}
