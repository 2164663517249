import { CommunityAvatar } from '@/components'
import type { SolcloudTypes } from '@/stores/solcloud'
import { css } from '@styled-system/css'
import { Box, VStack } from '@styled-system/jsx'
import { Link } from '@tanstack/react-router'
import type { FC } from 'react'

export interface MyCommunityItemProps {
  item: SolcloudTypes['CommunityType']
}

export const MyCommunityItem: FC<MyCommunityItemProps> = ({ item }) => {
  return (
    <Link to="/communities/$mint" params={{ mint: item.token.mint }}>
      <VStack
        css={{
          w: '64px',
          gap: '6px'
        }}>
        <Box
          css={{
            rounded: '16px',
            overflow: 'hidden',
            w: '64px',
            h: '64px',
            border: '1px solid rgba(0, 0, 0, 0.16)',
            pos: 'relative'
          }}>
          <Box
            css={{
              pos: 'absolute',
              top: 0,
              right: 0,
              bgColor: 'rgba(0, 0, 0, 0.7)',
              borderBottomLeftRadius: '12px',
              p: '2px 6px',
              color: '#FFF',
              boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.25)',
              fontSize: '12px',
              lineHeight: '14.06px'
            }}>
            {item.community.online_users_count}
          </Box>
          <CommunityAvatar
            src={item.token.logo}
            className={css({
              w: '100%',
              h: '100%'
            })}
          />
        </Box>
        <Box
          css={{
            maxW: '100%',
            textWrap: 'nowrap',
            textAlign: 'center',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: '12px',
            lineHeight: '14.32px',
            fontWeight: 510,
            color: 'rgba(0, 0, 0, 0.8)'
          }}>
          {item.token.symbol.length > 4 ? item.token.symbol.slice(0, 2) + '...' : item.token.symbol}
        </Box>
      </VStack>
    </Link>
  )
}
