import { useAuthenticationStore } from '@/stores'
import { solcloud, type SolcloudTypes } from '@/stores/solcloud'
import { useUserProfileQuery } from './useUserProfileQuery'

export const useTokenAccounts = (address?: SolcloudTypes['AddressItem']['address']) => {
  const { profile } = useUserProfileQuery()
  const currentAddress = address ?? profile?.primary_address
  const authenticated = useAuthenticationStore((state) => !!state.authToken)
  const { data, isLoading } = solcloud.useQuery(
    'tokenAccounts',
    { address: currentAddress! },
    {
      enabled: authenticated && !!currentAddress
    }
  )

  return { tokenAccounts: data, isLoading }
}
