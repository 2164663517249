import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react'
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui'
import '@solana/wallet-adapter-react-ui/styles.css'
import type { FC, PropsWithChildren } from 'react'
import { Configuration } from '@/configuration'
import { PhantomWalletAdapter, SolflareWalletAdapter } from '@solana/wallet-adapter-wallets'

const endpoint = Configuration.SOLANA_FRONTEND_HTTP_ENDPOINT

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface WalletAdapterProviderProps extends PropsWithChildren {}

export const WalletAdapterProvider: FC<WalletAdapterProviderProps> = ({ children }) => {
  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={[new PhantomWalletAdapter(), new SolflareWalletAdapter()]} autoConnect={true}>
        <WalletModalProvider>{children}</WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  )
}
