import { hc, type InferRequestType, type InferResponseType } from 'hono/client'
import type {
  SolcloudAppType,
  CommunityRoomServerMessage,
  JwtTokens,
  MessageType,
  TokenCommunityBaseType
} from '@turbx-sol/solcloud/client'
import { Configuration } from '@/configuration'
import { ensureValidJwtToken } from '../authentication'
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  type DefaultError,
  type QueryKey,
  type SetDataOptions,
  type UndefinedInitialDataInfiniteOptions,
  type Updater,
  type UseMutationOptions,
  type UseQueryOptions
} from '@tanstack/react-query'
import { queryClient } from '../query'
import type { BrandString } from '@turbx-libs/common'

const buildCommonHeaders = (): Record<string, string> => {
  return {
    'x-app-version': Configuration.APP_VERSION,
    'x-app-source': 'webapp'
  }
}

// https://hono.dev/docs/concepts/stacks#with-react
const solcloudClient = hc<SolcloudAppType>(Configuration.SOLCLOUD_API_ENDPOINT, {
  headers: async () => {
    const token = await ensureValidJwtToken()

    return {
      ...(token ? { authorization: `Bearer ${token.accessToken}` } : {}),
      ...buildCommonHeaders()
    }
  }
})

// https://hono.dev/docs/guides/rpc#client

const $solcloudApis = {
  // metadata
  metadata: solcloudClient['.well-known'].metadata,

  // auth
  mockLogin: solcloudClient.auth.__DEV_ONLY__mock,
  telegramLogin: solcloudClient.auth.telegramLogin,
  telegramWidgetLogin: solcloudClient.auth.telegramWidgetLogin,
  refreshToken: solcloudClient.auth.refreshToken,

  // account
  profile: solcloudClient.accounts.profile,
  updatePreference: solcloudClient.accounts.updatePreference,
  points: solcloudClient.accounts.points,

  // premium
  paymentGenerate: solcloudClient.premium.payment_generate,
  paymentVerify: solcloudClient.premium.payment_verify,

  // invitations
  invitationList: solcloudClient.invitations.list,
  invitationCreateDeprecated: solcloudClient.invitations.__DEV_ONLY__create,
  invitationDeleteDeprecated: solcloudClient.invitations.__DEV_ONLY__delete,

  // blob
  blobUpload: solcloudClient.blobs.upload, // TODO: Currently, the client does not support file uploading.

  // https://hono.dev/docs/helpers/websocket#rpc-mode
  // websocket
  wsCommunity: solcloudClient.ws.community,
  wsPersonal: solcloudClient.ws.personal,
  wsMatching: solcloudClient.ws.matching,

  // token accounts
  tokenAccounts: solcloudClient.accounts.tokenAccounts,
  balances: solcloudClient.accounts.balances,

  // token
  tokenSearch: solcloudClient.sol.token.search,
  tokenPrices: solcloudClient.sol.token.prices,

  // wallet
  addressList: solcloudClient.sol.address.list,
  addressEntitle: solcloudClient.sol.address.entitle,
  addressGenerate: solcloudClient.sol.address.generate,
  addressImport: solcloudClient.sol.address.import,
  addressRemove: solcloudClient.sol.address.remove,
  addressMakeDefault: solcloudClient.sol.address.makeDefault,

  withdrawAddressCreate: solcloudClient.sol.address.createWithdrawAddress,
  withdrawAddressUpdate: solcloudClient.sol.address.updateWithdrawAddress,
  withdrawAddressRemove: solcloudClient.sol.address.removeWithdrawAddress,

  // swap
  swapPreflightSSE: solcloudClient.sol.transaction.swapPreflightSSE,
  swapPreflight: solcloudClient.sol.transaction.swapPreflight,
  swapSend: solcloudClient.sol.transaction.swapSend,
  swapConfirm: solcloudClient.sol.transaction.swapConfirm,

  // transfer
  transferPreflight: solcloudClient.sol.transaction.transferPreflight,
  transferSend: solcloudClient.sol.transaction.transferSend,
  transferConfirm: solcloudClient.sol.transaction.transferConfirm,

  // community
  topTokens: solcloudClient.communities.topTokens,
  topCommunities: solcloudClient.communities.topCommunities,
  myCommunities: solcloudClient.communities.myCommunities,
  communityInfoByCommunityId: solcloudClient.communities.infoByCommunityId,
  communityInfoByMint: solcloudClient.communities.__DEV_ONLY__infoByMint,
  communityCreate: solcloudClient.communities.create,
  communityJoin: solcloudClient.communities.join,
  communityJoinOrCreate: solcloudClient.communities.joinOrCreate,
  communityLeave: solcloudClient.communities.leave,
  communityIdentities: solcloudClient.communities.communityIdentities,

  // messages && comments
  communityChatRoomMessages: solcloudClient.communities.chat_room_messages,
  communityRoomMessageCreate: solcloudClient.communities.chat_room_message_send,
  communityComments: solcloudClient.communities.comments,
  communityCommentsCreate: solcloudClient.communities.comment_send,
  reactionCreate: solcloudClient.communities.reactionCreate,
  reactionDelete: solcloudClient.communities.reactionDelete
} as const

type SolcloudApi = typeof $solcloudApis

type SolcloudMutations = {
  [K in keyof SolcloudApi]: SolcloudApi[K] extends { $post: unknown } ? K : never
}[keyof SolcloudApi]

// https://hono.dev/docs/concepts/stacks#with-react

const useSolcloudMutation = <
  Type extends SolcloudMutations,
  TData extends InferResponseType<SolcloudApi[Type]['$post']>,
  Req extends InferRequestType<SolcloudApi[Type]['$post']>,
  TVariables extends Req extends { json: infer R } ? R : void
>(
  type: Type,
  options?: Omit<UseMutationOptions<TData, Error, TVariables>, 'mutationFn'>
) => {
  return useMutation<TData, Error, TVariables>({
    ...options,
    mutationFn: async (value) =>
      (await (await $solcloudApis[type].$post((value ? { json: value } : undefined) as never)).json()) as never
  })
}

type SolcloudWebsockets = {
  [K in keyof SolcloudApi]: SolcloudApi[K] extends { $ws: unknown } ? K : never
}[keyof SolcloudApi]

const solcloudWebsocket = <Type extends SolcloudWebsockets>(type: Type): SolcloudApi[Type]['$ws'] => {
  return $solcloudApis[type].$ws
}

type SolcloudQueries = {
  [K in keyof SolcloudApi]: SolcloudApi[K] extends { $get: unknown } ? K : never
}[Exclude<keyof SolcloudApi, SolcloudInfiniteQueries | SolcloudWebsockets>]

// https://github.com/orgs/honojs/discussions/3075
// https://github.com/orgs/honojs/discussions/2475#discussioncomment-9073957

const solcloudInvalidateQuery = <
  Type extends SolcloudQueries,
  Req extends InferRequestType<SolcloudApi[Type]['$get']>,
  TVariables extends Req extends { query: infer R } ? R : void,
  TQueryKey extends QueryKey = QueryKey
>(
  type: Type,
  params: TVariables
) => {
  queryClient.invalidateQueries({ queryKey: [type, params] as unknown as TQueryKey })
}

const solcloudSetQueryData = <
  Type extends SolcloudQueries | SolcloudInfiniteQueries,
  Req extends InferRequestType<SolcloudApi[Type]['$get']>,
  TVariables extends Req extends { query: infer R } ? R : void,
  TQueryFnData = InferResponseType<SolcloudApi[Type]['$get']>,
  TData = TQueryFnData
>(
  type: Type,
  params: TVariables,
  updater: Updater<TData | undefined, TData | undefined>,
  options?: SetDataOptions
): TData | undefined => {
  return queryClient.setQueryData<TData>([type, params] as QueryKey, updater, options)
}

const useSolcloudQuery = <
  Type extends SolcloudQueries,
  Req extends InferRequestType<SolcloudApi[Type]['$get']>,
  TVariables extends Req extends { query: infer R } ? R : void,
  TQueryFnData = InferResponseType<SolcloudApi[Type]['$get']>,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  type: Type,
  params: TVariables,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>
) => {
  return useQuery<TQueryFnData, TError, TData, TQueryKey>({
    queryKey: [type, params] as unknown as TQueryKey,
    queryFn: async () => {
      const res = await $solcloudApis[type].$get({ query: params } as never)
      return (await res.json()) as never
    },
    ...options
  })
}

type SolcloudInfiniteQueries = 'communityChatRoomMessages' | 'communityComments'

const useSolcloudInfiniteQuery = <
  Type extends SolcloudInfiniteQueries,
  Req extends InferRequestType<SolcloudApi[Type]['$get']>,
  TVariables extends Req extends { query: infer R } ? R : void,
  TQueryFnData = InferResponseType<SolcloudApi[Type]['$get']>,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  type: Type,
  params: TVariables,
  options: Omit<UndefinedInitialDataInfiniteOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>
) => {
  return useInfiniteQuery<TQueryFnData, TError, TData, TQueryKey>({
    queryKey: [type, params] as unknown as TQueryKey,
    queryFn: async () => {
      const res = await $solcloudApis[type].$get({ query: params } as never)
      return (await res.json()) as never
    },
    ...options
  })
}

export const solcloud = {
  useMutation: useSolcloudMutation,
  useQuery: useSolcloudQuery,
  useInfiniteQuery: useSolcloudInfiniteQuery,
  invalidateQuery: solcloudInvalidateQuery,
  setQueryData: solcloudSetQueryData,
  websocket: solcloudWebsocket,
  apiClient: $solcloudApis
}

export interface SolcloudTypes {
  InvitationList: InferResponseType<(typeof $solcloudApis.invitationList)['$get']>['users']
  AddressItem: InferResponseType<(typeof $solcloudApis.addressList)['$get']>['addresses'][0]
  UserWithTokens: InferResponseType<(typeof $solcloudApis.mockLogin)['$post']>
  TokenAccountType: InferResponseType<(typeof $solcloudApis.tokenAccounts)['$get']>[0]
  CommunityId: BrandString<'SOL', 'communities'>
  CommunityType: InferResponseType<(typeof $solcloudApis.myCommunities)['$get']>[0]
  MaybeCommunityType: InferResponseType<(typeof $solcloudApis.topTokens)['$get']>[0]
  TokenCommunityBaseType: TokenCommunityBaseType
  CommunityInfoType: InferResponseType<(typeof $solcloudApis.communityInfoByCommunityId)['$get']>
  JwtTokens: JwtTokens
  MessageType: MessageType
  UserMessageType: MessageType['account']
  UserProfileType: InferResponseType<(typeof $solcloudApis.profile)['$get']>['account']
  ReactionType: MessageType['reactions'][0]
  TextMessagePayload: Extract<MessageType['payload'], { type: 'Text' }>
  CommunityChatRoomMessages: InferResponseType<(typeof $solcloudApis.communityChatRoomMessages)['$get']>['rows']
  CommunityRoomServerMessage: CommunityRoomServerMessage
  SwapSendRequestParams: InferRequestType<(typeof $solcloudApis.swapSend)['$post']>['json']
  TransferSendRequestParams: InferRequestType<(typeof $solcloudApis.transferSend)['$post']>['json']
  PriceItem: InferResponseType<(typeof $solcloudApis.tokenPrices)['$get']>[0]
  TelegramWidgetLoginData: InferRequestType<(typeof $solcloudApis.telegramWidgetLogin)['$post']>['json']['data']
}
