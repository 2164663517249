import { useEffect, useRef, useState } from 'react'
import defaultAvatar from '@/assets/community/default-avatar.png'

export const useCommunityAvatar = (src: string | null | undefined) => {
  const [imageSrc, setImageSrc] = useState(src)
  const imageRef = useRef(new Image())

  useEffect(() => {
    setImageSrc(src)
  }, [src])

  useEffect(() => {
    imageRef.current.src = imageSrc ?? ''
  }, [imageSrc])

  useEffect(() => {
    imageRef.current.onerror = () => {
      if (imageSrc !== defaultAvatar) setImageSrc(defaultAvatar)
    }
  }, [imageSrc])

  return imageSrc
}
