import { Configuration } from '@/configuration'
import eruda from 'eruda'
import buildTime from '~build/time'
import { sha } from '~build/git'

export const enableDebugger = (): void => {
  eruda.init()

  console.info('buildTime', buildTime.toString())
  console.info('sha', sha)
}

export const initDebugger = (): void => {
  if (Configuration.ENABLE_DEBUGGER) {
    enableDebugger()
  }
}
