import { createFileRoute, Outlet, type RouteComponent } from '@tanstack/react-router'
import { CommunityList } from './_index/components/community-list.component'
import { Grid, GridItem } from '@styled-system/jsx'
import { useBreakpoint } from '@/utils'

const IndexLayout: RouteComponent = () => {
  const isMd = useBreakpoint('md')

  return (
    <Grid
      css={{
        display: 'block',
        gridGap: 0,
        md: {
          display: 'grid',
          gridTemplateColumns: `minmax(auto, 336px) 1fr`
        }
      }}>
      <GridItem hideBelow="md">{isMd && <CommunityList />}</GridItem>
      <GridItem
        css={{
          md: {
            bgColor: 'rgba(242, 242, 242, 1)'
          }
        }}>
        <Outlet />
      </GridItem>
    </Grid>
  )
}

export const Route = createFileRoute('/_authenticated/_navigable/_index')({
  component: IndexLayout
})
