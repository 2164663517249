import { solcloud, type SolcloudTypes } from '@/stores'
import { VStack } from '@styled-system/jsx'
import { Spinner } from '@turbx-libs/design-system/components'
import { useEffect, useRef, type FC } from 'react'

declare global {
  interface Window {
    onTelegramAuth: (user: SolcloudTypes['TelegramWidgetLoginData']) => void
  }
}

export interface TelegramSignInButtonProps {
  onSignIn: (data: SolcloudTypes['TelegramWidgetLoginData']) => void
}

export const TelegramSignInButton: FC<TelegramSignInButtonProps> = ({ onSignIn }) => {
  const ref = useRef<HTMLDivElement>(null)

  const { data } = solcloud.useQuery('metadata', undefined)

  // 本地如果要测试 tg 登录功能，就要把 ssl 关掉，并且端口改成 80，并用 127.0.0.1 访问
  // 这样 tg bot 的 domain 才能对上
  useEffect(() => {
    if (!data?.tgbot.username) return

    const script = document.createElement('script')
    script.async = true
    script.src = 'https://telegram.org/js/telegram-widget.js?22'
    script.setAttribute('data-size', 'large')
    script.setAttribute('data-telegram-login', data.tgbot.username)
    script.setAttribute('data-request-access', 'write')
    script.setAttribute('data-radius', '8px')
    // script.setAttribute('data-userpic', 'false')
    window.onTelegramAuth = async (data) => {
      onSignIn(data)
    }
    // https://core.telegram.org/widgets/login#receiving-authorization-data
    script.setAttribute('data-onauth', 'window.onTelegramAuth(user)')

    if (ref.current?.firstChild) {
      ref.current.removeChild(ref.current.firstChild)
    }
    ref.current?.appendChild(script)
  }, [data?.tgbot.username, onSignIn])

  if (!data?.tgbot.username) return <Spinner size={18} />

  return <VStack ref={ref} />
}
