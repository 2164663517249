import { type FC } from 'react'
import { useNavbarStore } from './navbar.hook'
import { Icon, Pressable } from '@turbx-libs/design-system/components'
import { Link } from '@tanstack/react-router'
import { css } from '@styled-system/css'
import { createDataset } from '@/utils'
import { Box, HStack, VStack } from '@styled-system/jsx'
import { useTranslation } from 'react-i18next'

import logoPurple from '@/assets/logo-purple.png'
import { router } from '@/initializers'

export const footerHeight = `58px`

const iconCss = css({
  w: '24px',
  h: '24px'
})

const navItemCss = css({
  flex: 1,
  p: `8px 16px 24px`,
  md: {
    p: 0,
    h: '48px',
    flex: 'unset',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

const navItemInnerCss = css.raw({
  flex: 1,
  gap: '4px',
  fontSize: '12px',
  lineHeight: '14.32px',
  fontWeight: 400,
  color: 'rgba(171, 171, 171, 1)',
  _active: {
    color: '#000'
  }
})

export const Navbar: FC = () => {
  const { t } = useTranslation()
  const visible = useNavbarStore((state) => state.visible)

  if (!visible) return null

  return (
    <Box
      css={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        md: {
          position: 'relative',
          bottom: 'unset',
          left: 'unset',
          right: 'unset',
          borderRight: '0.5px solid rgba(0, 0, 0, 0.12)'
        }
      }}>
      <VStack
        hideBelow="md"
        css={{
          marginTop: '32px',
          marginBottom: '48px',
          cursor: 'pointer'
        }}
        onClick={() => router.navigate({ to: '/' })}>
        <img
          className={css({
            w: '32px',
            h: '32px'
          })}
          src={logoPurple}
        />
      </VStack>
      <HStack
        css={{
          h: `calc(58px + 24px)`,
          w: '100%',
          bgColor: '#FFF',
          flexDirection: 'row',
          md: {
            flexDirection: 'column',
            w: '64px',
            h: 'unset',
            gap: '4px',
            p: 0
          }
        }}>
        <Link
          className={css({
            w: '100%'
          })}
          to="/">
          {({ isActive }) => (
            <Pressable>
              <Box className={navItemCss}>
                {/* eslint-disable-next-line @pandacss/no-dynamic-styling, @pandacss/no-property-renaming */}
                <VStack {...createDataset({ 'data-active': isActive })} css={navItemInnerCss}>
                  <Icon className={iconCss} name={isActive ? 'home-fill' : 'home-line'} />
                  <Box hideFrom="md">{t('common.navbar.home.label')}</Box>
                </VStack>
              </Box>
            </Pressable>
          )}
        </Link>
        <Link
          className={css({
            w: '100%'
          })}
          to="/ranking">
          {({ isActive }) => (
            <Pressable>
              <Box className={navItemCss}>
                {/* eslint-disable-next-line @pandacss/no-dynamic-styling, @pandacss/no-property-renaming */}
                <VStack {...createDataset({ 'data-active': isActive })} css={navItemInnerCss}>
                  <Icon className={iconCss} name={isActive ? 'bubble-chart-fill' : 'bubble-chart-line'} />
                  <Box hideFrom="md">{t('common.navbar.ranking.label')}</Box>
                </VStack>
              </Box>
            </Pressable>
          )}
        </Link>
        <Link
          className={css({
            w: '100%'
          })}
          to="/quest">
          {({ isActive }) => (
            <Pressable>
              <Box className={navItemCss}>
                {/* eslint-disable-next-line @pandacss/no-dynamic-styling, @pandacss/no-property-renaming */}
                <VStack {...createDataset({ 'data-active': isActive })} css={navItemInnerCss}>
                  <Icon className={iconCss} name={isActive ? 'user-fill' : 'user-line'} />
                  <Box hideFrom="md">{t('common.navbar.invite.label')}</Box>
                </VStack>
              </Box>
            </Pressable>
          )}
        </Link>
        <Link
          className={css({
            w: '100%'
          })}
          to="/wallet">
          {({ isActive }) => (
            <Pressable>
              <Box className={navItemCss}>
                {/* eslint-disable-next-line @pandacss/no-dynamic-styling, @pandacss/no-property-renaming */}
                <VStack {...createDataset({ 'data-active': isActive })} css={navItemInnerCss}>
                  <Icon className={iconCss} name={isActive ? 'wallet-fill' : 'wallet-line'} />
                  <Box hideFrom="md">{t('common.navbar.wallet.label')}</Box>
                </VStack>
              </Box>
            </Pressable>
          )}
        </Link>
      </HStack>
    </Box>
  )
}
