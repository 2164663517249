import 'virtual:svg-icons-register'
import 'react-toastify/dist/ReactToastify.css'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import './setup.css'
import { RouterProvider } from '@tanstack/react-router'
import { QueryClientProvider } from '@tanstack/react-query'
import { initDebugger, initI18n, initMonitor, router } from '@/initializers'
import { HelmetProvider } from 'react-helmet-async'
import { queryClient, RuntimeType, useOSStore, WalletAdapterProvider } from './stores'

initDebugger()
initMonitor()
initI18n()

const rootElement = document.getElementById('root')!
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement)

  root.render(
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <WalletAdapterProvider>
            <RouterProvider router={router} />
          </WalletAdapterProvider>
        </HelmetProvider>
      </QueryClientProvider>
    </StrictMode>
  )

  if (useOSStore.getState().runtime === RuntimeType.TMA) {
    window.Telegram.WebApp.ready()
  }
}
